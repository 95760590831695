import {filter, first, map, tap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild,} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {UiService} from 'src/app/services/ui.service';
import {AppService} from 'src/app/services/app.service';
import {HeaderNavItem} from "../../interfaces/ui-components.interface";
import {
    AttractionMainSearchProductEvent
} from "../../dispatch/events/attractions-main/attraction-main-search-product.event";
import {environment} from "../../../environments/environment";
import {ChangeLanguageEvent} from "../../dispatch/events/home/change-language.event";
import {LocaleEnum} from "../../../environments/enums/locale.enum";
import {languages} from "../../../environments/configs/languages";
import {is} from "immutable";
import {Banner} from "../../models/home/banner.model";
import {ImageSize} from "../../attractions/enums/image-size.enum";
import {AttractionProvider} from "../../providers/attraction.provider";
import {ImageObject} from "../../interfaces/ng-slider.interface";
import {CognitoService} from "../../services/cognito.service";
import {PlusService} from "../../modules/plus/plus.service";

interface SelectableLanguage {
    label: string;
    locale: LocaleEnum;
}

@Component({
    selector: 'qup-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
    @ViewChild('toggleMenuButton') toggleMenuButton!: ElementRef;
    private _subs: Subscription[] = [];
    public isMobile!: boolean;
    public currentLanguage!: LocaleEnum;
    public _showHeaderSearch = false;
    public banners: Banner[] = [];
    public bannersMobile: ImageObject[] = [];
    public isLoggedIn = false;
    public walletBalance = 0;

    public languageForm = this.formBuilder.group({
        selectedLang: [this.appService.currentLanguage],
    });

    public selectableLangs: SelectableLanguage[] = languages;

    get showHeaderSearch(): boolean {
        return this._showHeaderSearch;
    }

    set showHeaderSearch(s: boolean) {
        this._showHeaderSearch = s;
        this.uiService.showHeaderSearch$.next(s);
    }

    form: FormGroup = this.formBuilder.group({
        search: [null],
    });

    public navigationItems: HeaderNavItem[] = [
        {
            route: ['/'],
            label: '<i style="font-size: 16px" class="material-symbols-outlined">home</i> Home'
        },
        {
            route: ['/account/download-app'],
            label: '<i style="font-size: 16px" class="material-symbols-outlined">attractions</i> Dagje Uit App',
        },
        {
            route: ['/how-it-works'],
            label: '<i style="font-size: 16px" class="material-symbols-outlined">help</i> Hoe werkt het',
        },
        {
            route: ['/account/home'],
            label: '<i style="font-size: 16px" class="material-symbols-outlined">qr_code_2</i> Verzilver je voucher',
        },
    ];

    public toggle = false;

    constructor(
        private uiService: UiService,
        private appService: AppService,
        private changeDetectorRef: ChangeDetectorRef,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private attractionMainSearchProductEvent: AttractionMainSearchProductEvent,
        private changeLanguageEvent: ChangeLanguageEvent,
        private attractionProvider: AttractionProvider,
        private cognitoService: CognitoService,
        private walletService: PlusService
    ) {
        if (environment.modules.changeDate) {
            this.navigationItems.push({route: ['/change-date'], label: 'HEADER.change_date'});
        }

        if (environment.modules.lostTickets) {
            this.navigationItems.push({route: ['/lost-tickets'], label: '<i style="font-size: 16px" class="material-symbols-outlined">local_activity</i> Tickets verloren?'});
        }
    }

    ngOnInit(): void {

        this.showHeaderSearch = !!this.showHeaderSearchRoute();

        this._subs.push(
            this.attractionProvider.campaign().subscribe((data) => {
                this.banners = data.banners();
                this.bannersMobile = data.banners().map((banner) => {
                    return {
                        image: banner.mobileBannerUuid()?.replace('{{resolution}}', ImageSize.MOBILE_BANNER),
                        thumbImage: banner.mobileBannerUuid()?.replace('{{resolution}}', ImageSize.MOBILE_BANNER),
                        alt: 'banner',
                        title: 'banner'
                    } as ImageObject;
                }) as ImageObject[];
            }),
            this.uiService.isMobileOverlay$
                .pipe(
                    tap((isMobile) => {
                        this.isMobile = isMobile;
                        this.changeDetectorRef.detectChanges();
                    })
                ).subscribe(),

            this.router.events
                .pipe(
                    filter((event: any) => event instanceof NavigationEnd),
                    map(this.showHeaderSearchRoute.bind(this)),
                    map((show) => !!show)
                )
                .subscribe((showHeaderSearch: boolean) => {
                    this.showHeaderSearch = showHeaderSearch;
                    this.uiService.resetSearchForm();
                }),

            (this.languageForm.get('selectedLang') as AbstractControl).valueChanges.subscribe((lang) => {
                this.changeLanguage(lang);
            }),
            this.appService.currentLanguage$.subscribe(
                (currentLanguage) => {
                    this.currentLanguage = currentLanguage;

                    const c = this.languageForm.get('selectedLang');
                    c?.patchValue(currentLanguage, {emitEvent: false});
                },
                (error) => {
                    console.error('ERROR: ', error);
                }
            ),
            this.cognitoService.isLoggedIn().subscribe((isLoggedIn) => {
                    this.isLoggedIn = isLoggedIn;

                    if (this.isLoggedIn) {
                        this.walletService.getWallet().pipe(first()).subscribe((wallet) => {
                            this.walletBalance = wallet.euroBalance();
                        });
                    }
                }
            ),
        );
    }

    submitSearch(): void {
        let searchTerm = this.form.get('search')?.value;
        this.attractionMainSearchProductEvent.dispatch(searchTerm);
        this.form.reset();
    }

    toggleMenu(): void {
        this.toggle = !this.toggle;
        if (this.toggle) {
            this._showHeaderSearch = false;
        } else {
            this._showHeaderSearch = true;
        }
    }

    closeMenu(): void {
        if (this.toggle) {
            this.toggleMenu();
        }
    }

    changeLanguage(language: LocaleEnum): void {
        this.changeLanguageEvent.dispatch(language);
        this.appService.selectCurrentLanguage(language);
    }

    /**
     * Check if header search should be displayed.
     * 'showHeaderSearch' is a static property defined on the route definition in route modules
     */
    showHeaderSearchRoute(): any {
        let child = this.activatedRoute.firstChild;
        while (child) {
            if (child.firstChild) {
                child = child.firstChild;
            } else if (
                child.snapshot.data &&
                child.snapshot.data['showHeaderSearch']
            ) {
                return child.snapshot.data['showHeaderSearch'];
            } else {
                return null;
            }
        }
        return null;
    }

    navigateTo(page: string): void {
        this.router.navigate([page]);
    }

    ngOnDestroy(): void {
        this._subs.forEach((s) => s?.unsubscribe());
    }

    handleClick(i: HeaderNavItem): void {
        if (i?.click) {
            i.click();
        }
    }

    protected readonly is = is;
}
